import React, { useContext, useState, useEffect } from "react";
import { FilterContext } from '../../utils/context'
import { FiChevronDown } from 'react-icons/fi'
import {AiOutlineClose} from 'react-icons/ai'
import * as styles from './filterRecipeGroup.module.scss'
import { useDictionaryQuery } from "../../fragments/hooks/useDictionaryQuery";
import Checkbox from "../Checkbox/Checkbox";
const FilterRecipeGroup = ({ filterGroups }) => {
    //  const { allFilterSections } = useSiteQuery()
    const [showFilter, setShowFilter] = useState(false)

    const checked = (e) => {
        if (e.target.checked) {
            e.target.labels[0].classList.add(styles.filterCheckboxContainerChecked)
        }
        else {
            e.target?.labels && e.target.labels[0].classList.remove(styles.filterCheckboxContainerChecked)
        }
    }

    // const locale = useLocale()
    const{clear, filterBy, apply} = useDictionaryQuery()
    const {
        recipeFilter,
        setRecipeFilter
    } = useContext(FilterContext)

    const clearAllFilters = () => {
        const allElements = document.querySelectorAll('.clear-checkbox')
        allElements.forEach(element => {
            element.checked = false
            setRecipeFilter([])
        })
    }
    const onUpdateCheckbox = (item, state, setter) => {
        const isClient = typeof window === 'object'
        if (!isClient) return false
        if (item && !state.includes(item)) {
            setter([...state, item])
        }
        else if (item && state.includes(item)) {
            const index = state.indexOf(item)
            const temp = state
            if (index > -1) {
                temp.splice(index, 1)
                setter([...temp])
            }
        }
    }

    
    return (
        <div>
            <div className={styles.filterGroups}>
                <input type = 'checkbox' id = 'mobile-filter' hidden className = {styles.filterInput}></input>
                <label htmlFor="mobile-filter"  className={styles.filterHeadMobile} >
                    <div className={styles.filterBy}>Filter By:</div>
                    <div className={styles.arrow}> <FiChevronDown fontSize='1.5rem' /> </div>
                </label>
                {/*----------------------- mobile view of filter start -----------------------------------*/}
                {
                    <div className={styles.mobileFilter}>
                        <div className={styles.closeWrapper}>
                            <div className={styles.close} onClick = {() => {document.getElementById('mobile-filter').checked = false}}> <AiOutlineClose fontSize='1.5rem'/> </div>
                        </div>
                        <div className={styles.filterHeadMobilePopUp} >
                            <div className={styles.filterBy}>{filterBy}:</div>
                            <div><p className={styles.clear} onClick = {e => {clearAllFilters()}}>{clear}</p></div>
                        </div>
                        {
                            filterGroups?.map((filterCategory: any, index:any) => {
                                return (
                                    <div className={styles.filterPopUp} key = {index} >
                                        <input type = 'checkbox' id = {`mobile-${index}`} hidden ></input>
                                        { <div className={` ${styles.filterSecondMobile}`} id = {`checkbox-${index}`}>
                                            {
                                                filterCategory.filterItems?.map((filter: any, index: number) => {
                                                    let activeState = recipeFilter;
                                                    let activeSetter = setRecipeFilter;
                                                    // let isActive = recipeFilter && recipeFilter.include(filter.title);
                                                    //if (!filterCategory.filterGroupTitle || !filter.filterTitle) return;

                                                    const clickHandler = () => {
                                                        onUpdateCheckbox(filter.filterTitle, activeState, activeSetter);
                                                    }

                                                    return (
                                                        <div key={index} >
                                                            <Checkbox index={index} filter={filter} clickHandler={clickHandler} />
                                                        </div>
                                                    )
                                                })
                                            }
                                        </div>}
                                    </div>
                                )
                            })
                        }
                        <div className={`${styles.applyBtn}`} onClick = {() => {document.getElementById('mobile-filter').checked = false}}>
                        <label htmlFor="mobile-filter" > <button className={`red-button`} > {apply} </button> </label>
                        </div>
                    </div>
                }
                {/* --------------------------mobile view of filter end -------------------------------------------- */}
                
                {/* --------------------------desktop view of filter start -------------------------------------------*/}
                {filterGroups?.map((filterCategory: any, id:number) => {
                    return (
                            <div className={styles.desktopFilter} key = {id}>
                                <div className={` ${styles.filterSecond}`}>
                                    {
                                        filterCategory.filterItems.map((filter: any, index: number) => {
                                            let activeState = recipeFilter;
                                            let activeSetter = setRecipeFilter;
                                           // let isActive = recipeFilter && recipeFilter.include(filter.title);
                                            // if (!filterCategory.filterGroupTitle || !filter.filterTitle) return;

                                            const clickHandler = () => {
                                                onUpdateCheckbox(filter.filterTitle, activeState, activeSetter);
                                            }

                                            return (
                                                <div key={index} className = {styles.filterCheckbox}>
                                                    <Checkbox index={index} filter={filter} clickHandler={clickHandler} />
                                                </div>
                                            )
                                        })
                                    }
                                </div>
                            </div>
                    )
                })}
                {/* desktop view of filter end */}
            </div>
        </div>
    )
}

export default FilterRecipeGroup