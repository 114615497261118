// extracted by mini-css-extract-plugin
export var button = "categoryRecipeCard-module--button--14f7d";
export var cafeFormIntro = "categoryRecipeCard-module--cafe-form-intro--a454b";
export var card = "categoryRecipeCard-module--card--04bde";
export var cardImage = "categoryRecipeCard-module--card-image--c57ed";
export var cardInfo = "categoryRecipeCard-module--card-info--551d9";
export var cardTitle = "categoryRecipeCard-module--card-title--44fd3";
export var cardWrapper = "categoryRecipeCard-module--card-wrapper--22089";
export var highlight = "categoryRecipeCard-module--highlight--44a8f";
export var recipeBtn = "categoryRecipeCard-module--recipeBtn--d4055";
export var redButton = "categoryRecipeCard-module--red-button--a7bcd";
export var redTransparentButton = "categoryRecipeCard-module--red-transparent-button--cbdca";
export var transparentButton = "categoryRecipeCard-module--transparent-button--c4c01";
export var type = "categoryRecipeCard-module--type--5b94e";