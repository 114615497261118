// extracted by mini-css-extract-plugin
export var Modal = "recipeModal-module--Modal--743f8";
export var ModalOverlay = "recipeModal-module--ModalOverlay--38adb";
export var button = "recipeModal-module--button--31048";
export var cafeFormIntro = "recipeModal-module--cafe-form-intro--76111";
export var closeBtn = "recipeModal-module--closeBtn--444c1";
export var highlight = "recipeModal-module--highlight--ec239";
export var modalSection = "recipeModal-module--modalSection--924e5";
export var redButton = "recipeModal-module--red-button--f8fab";
export var redTransparentButton = "recipeModal-module--red-transparent-button--db402";
export var show = "recipeModal-module--show--4b2d2";
export var transparentButton = "recipeModal-module--transparent-button--9c039";