import React from "react"
import * as styles from "./title.module.scss"

type Props = {
  headingLevel: string,
  title: string,
  firstItalicContent: Boolean,
  titleBreak:Boolean
}

const Title = ({ headingLevel, title, firstItalicContent, titleBreak }: Props) => {
  if (!title) return <></>

  switch (headingLevel) {
    case "h1":
      return (
        <h1 className="heading heading-1">
          {firstItalicContent
            ? title?.split("/").map((phrase, index) => (
                <span key={index} className={styles.spanLayoutItalic}>
                  {phrase}
                </span>
              ))
            : title?.split("/").map((phrase, index) => (
                <span key={index} className={styles.spanLayout}>
                  {phrase}
                </span>
              ))}
        </h1>
      )
    case "h2":
      return (
        <h2 className="heading heading-2">
          {firstItalicContent
            ? title?.split("/").map((phrase, index) => (
                <span key={index} className={styles.spanLayoutItalic}>
                  {phrase}
                </span>
              ))
            : title?.split("/").map((phrase, index) => (
                <span key={index} className={styles.spanLayout}>
                  {phrase}
                </span>
              ))}
        </h2>
      )
    case "h3":
      return (
        <h3 className="heading heading-3">
          {firstItalicContent
            ? title?.split("/").map((phrase, index) => (
                <span key={index} className={styles.spanLayoutItalic}>
                  {phrase}
                </span>
              ))
            : title?.split("/").map((phrase, index) => (
                <span key={index} className={styles.spanLayout}>
                  {phrase}
                </span>
              ))}
        </h3>
      )
    case "h4":
      return (
        <h4 className="heading heading-4">
          {firstItalicContent
            ? title?.split("/").map((phrase, index) => (
                <span key={index} className={styles.spanLayoutItalic}>
                  {phrase}
                </span>
              ))
            : title?.split("/").map((phrase, index) => (
                <span key={index} className={styles.spanLayout}>
                  {phrase}
                </span>
              ))}
        </h4>
      )
    case "h5":
      return (
        <h5 className="heading heading-5">
          {firstItalicContent
            ? title?.split("/").map((phrase, index) => (
                <span key={index} className={styles.spanLayoutItalic}>
                  {phrase}
                </span>
              ))
            : title?.split("/").map((phrase, index) => (
                <span key={index} className={styles.spanLayout}>
                  {phrase}
                </span>
              ))}
        </h5>
      )
  }

  return (
    <p>
      {titleBreak?
      firstItalicContent
        ? title?.split("/").map((phrase, index) => (
            <span key={index} className={styles.spanLayoutItalic}>
              {phrase}<br/>
            </span>
          ))
        : title?.split("/").map((phrase, index) => (
            <span key={index} className={styles.spanLayout}>
              {phrase}<br/>
            </span>
          ))
      
          :

          firstItalicContent
          ? title?.split("/").map((phrase, index) => (
              <span key={index} className={styles.spanLayoutItalic}>
                {phrase}
              </span>
            ))
          : title?.split("/").map((phrase, index) => (
              <span key={index} className={styles.spanLayout}>
                {phrase}
              </span>
            ))
        }
    </p>
  )
}

export default Title
