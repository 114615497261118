// extracted by mini-css-extract-plugin
export var applyBtn = "filterRecipeGroup-module--apply-btn--5db5a";
export var arrow = "filterRecipeGroup-module--arrow--ccd6d";
export var button = "filterRecipeGroup-module--button--e41b1";
export var cafeFormIntro = "filterRecipeGroup-module--cafe-form-intro--2d7ea";
export var clear = "filterRecipeGroup-module--clear--d9363";
export var close = "filterRecipeGroup-module--close--f014f";
export var closeWrapper = "filterRecipeGroup-module--close-wrapper--61189";
export var desktopFilter = "filterRecipeGroup-module--desktop-filter--aaf85";
export var filterBy = "filterRecipeGroup-module--filter-by--b85a4";
export var filterCheckbox = "filterRecipeGroup-module--filter-checkbox--17b31";
export var filterCheckboxContainer = "filterRecipeGroup-module--filter-checkbox-container--c0362";
export var filterCheckboxContainerChecked = "filterRecipeGroup-module--filter-checkbox-container-checked--d4195";
export var filterGroups = "filterRecipeGroup-module--filter-groups--9a8ff";
export var filterHeadDesktop = "filterRecipeGroup-module--filter-head-desktop--8f30d";
export var filterHeadMobile = "filterRecipeGroup-module--filter-head-mobile--17305";
export var filterHeadMobilePopUp = "filterRecipeGroup-module--filter-head-mobile-pop-up--e6990";
export var filterInput = "filterRecipeGroup-module--filter-input--df88a";
export var filterPopUp = "filterRecipeGroup-module--filter-pop-up--d94fc";
export var filterSecond = "filterRecipeGroup-module--filter-second--88c84";
export var filterSecondMobile = "filterRecipeGroup-module--filter-second-mobile--d6ef3";
export var filterTitle = "filterRecipeGroup-module--filter-title--ccc58";
export var filterTitleGrey = "filterRecipeGroup-module--filter-title-grey--fa826";
export var filters = "filterRecipeGroup-module--filters--efe37";
export var highlight = "filterRecipeGroup-module--highlight--daa6e";
export var mobileFilter = "filterRecipeGroup-module--mobile-filter--0cade";
export var redButton = "filterRecipeGroup-module--red-button--4bfb2";
export var redTransparentButton = "filterRecipeGroup-module--red-transparent-button--0a466";
export var transparentButton = "filterRecipeGroup-module--transparent-button--49845";