import React, { useState, useContext, useEffect } from 'react'
import Title from '../Shared/Title/Title'
import * as styles from './sectionRecipeOverviewGrid.module.scss'

import { FilterContext } from '../../utils/context';

import FilterRecipeGroup from '../FilterRecipeGroup/FilterRecipeGroup';
import CategoryRecipeCard from '../CategoryRecipeCard/CategoryRecipeCard';
import { RichText } from '../Shared/RichText/RichText';


const SectionRecipeOverviewGrid = ({ filterGroups, recipeGrid, title, slug}) => {
    const [gridItems, setGridItems] = useState(null);
    const [allRecipes,] = useState(recipeGrid.recipes)
    const [showAll, setShowAll] = useState(false)
    // const { loadMoreProducts } = useDictionaryQuery()
    // console.log(recipeGrid)

    const {
        recipeFilter,
    } = useContext(FilterContext)

    useEffect(() => {
        const prod = [...allRecipes];
        filterByRecipe(prod)
    }, [recipeFilter])

    const getItemsFromFilter = (items: any, filterGroup: any) => {
        return items.filter((item: any) => {
            let matched = false;
            if (item.filters) {
                item.filters.map((filter: any) => {
                    if (filterGroup.includes(filter.filterTitle && filter.filterTitle)) {
                        matched = true;
                        return false;
                    }
                })
            }
            return matched
        })
    }
    const filterByRecipe = (array: any) => {
        if (recipeFilter.length > 0) {
            const filteredProdsByCollection = getItemsFromFilter(array, recipeFilter)
            return createFilteredRecipes(filteredProdsByCollection)
        } else {
            return createFilteredRecipes(array)
        }
    }

    const createFilteredRecipes = async (array: any) => {
        const amountOfActiveFilters = recipeFilter.concat(recipeFilter).length;
        setGridItems(array)
    }
    return (
        <div className="container container-lg">
            <div className={styles.wrapper}>
                {title && <div className={styles.title}>
                    <Title title = {title} headingLevel = 'h2'/>
                </div>}
                {filterGroups && <div className={styles.filter}>
                    <FilterRecipeGroup filterGroups={filterGroups} />
                </div>}
                <div className={styles.contentWrapper}>
                    <div className={styles.productWrapper}>
                        {gridItems?.map((recipe: any, id: number) => {
                            return (
                                <CategoryRecipeCard data={recipe} key = {id}/>
                            )
                        })}
                    </div>
                </div>

            </div>
        </div>
    )
}

export default SectionRecipeOverviewGrid