import React from 'react'
import RecipeHero from '../RecipeHero/RecipeHero'
import ComponentContent from '../ComponentContent/ComponentContent'
import * as styles from "./recipeModal.module.scss"



const RecipeModal = ({modalData, onClose, show, recipePopupChecker}) => {

  if(!modalData){
    return <div></div>
  }

  return (
    <>
        <div
            className={`${styles.ModalOverlay} ${show ? styles.show : ""}`}
            onClick={() => onClose(null)}
        ></div>
        <div className={`${styles.Modal} ${show ? styles.show : ""}`}>
        <button
            onClick={onClose}
            className={styles.closeBtn}
            >
                &#10005;
            </button>
        <section className={styles.modalSection}>
        {
            modalData.map((section, index) =>{
            switch(section.__typename){
                case "ContentfulComponentHero":
                return <RecipeHero data = {section} recipePopupChecker={recipePopupChecker} key = {index}/>
                case "ContentfulComponentContent":
                return <ComponentContent data = {section} recipePopupChecker={recipePopupChecker} key = {index}/>
            }
            })
        }
        </section>
        </div>
    </>
  )
}

export default RecipeModal