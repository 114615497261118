import React from 'react'
import { graphql } from "gatsby"
import HeroComponent from '../components/HeroComponent/HeroComponent'
import SectionRecipeOverviewGrid from '../components/SectionRecipeOverviewGrid/SectionRecipeOverviewGrid'
import Seo from '../components/seo'
import { useUrl } from '../fragments/hooks/useUrl'
import Breadcrum from '../components/Breadcrum/Breadcrum'

const RecipesCategory = ({
  location,
  pageContext,
  data: {
    contentfulPageRecipesCategory: {
      heroBanner,
      title,
      recipeGrid,
      seoDescription,
      seoTitle,
      slug,
      filterGroups,
      breadcrum,
      thumbnailTitle,
    },
    allContentfulPageRecipesCategory: {
        nodes
    }
  }
 
}) => {
  const lang = useUrl()
 
  return (
    <section id = 'product-category'>
      <Seo
        slug={slug}
        title={seoTitle}
        description={seoDescription && seoDescription.seoDescription}
        node_locale={pageContext.node_locale}
        lang={lang}
        hrefLangs={nodes}/>
      {breadcrum && <Breadcrum data = {breadcrum} title={'Recipes'}/>}
      <HeroComponent data = {heroBanner} />
      <SectionRecipeOverviewGrid 
        filterGroups = {filterGroups}
        recipeGrid = {recipeGrid}
        title = {title}
        slug={slug}
        />
    </section>
  )
}


export const CategoryRecipeQuery = graphql`
  query CategoryRecipe($id: String!, $contentful_id: String!) {
    contentfulPageRecipesCategory(id: { eq: $id }) {
      slug
      seoTitle
      seoDescription {
        seoDescription
      }
      breadcrum {
        title
        slug
      }
      title
      thumbnailImage {
        gatsbyImageData
        title
      }
      heroBanner {
        heroImage {
          gatsbyImageData
          title
        }
        heroImageMobile {
          gatsbyImageData
          title
        }
      }
      filterGroups {
        filterGroupTitle
        filterType
        filterItems {
          filterTitle
        }
      }
      recipeGrid {
        recipes {
          slug
          thumbnailTitle
          recipeType
          thumbnailImage {
            gatsbyImageData
            title
          }
          filters {
            filterTitle
          }
          recipePopupChecker
          sections {
              __typename
              ...componentHero
              ...componentContent
          }
        }
      }
    }
    allContentfulPageRecipesCategory(
      filter: {
        contentful_id: { eq: $contentful_id }
        id: { ne: $id }
        slug: { ne: null }
      }
    ) {
      nodes {
        slug
        node_locale
      }
    }
  }
`
export default RecipesCategory